import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { BackendEndpoints } from '@memberspot/models';
import {
  GetSchoolIdsForPermissionDtos,
  Permission,
  PermissionModule,
  SchoolAdminPermission,
} from '@memberspot/shared/model/permission';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SchoolAdminPermissionStore } from './school-admin-permission.store';

@Injectable({
  providedIn: 'root',
})
export class SchoolAdminPermissionService {
  constructor(
    private http: HttpBackendService,
    private store: SchoolAdminPermissionStore,
  ) {}

  removeActive() {
    this.store.setActive(null);
  }

  getSchoolIdsForPermission(): Observable<GetSchoolIdsForPermissionDtos> {
    return this.http.generic(
      BackendEndpoints.SCHOOL_ADMINS.GET_DUPLICATE_COURSE_PERMISSION_SCHOOLS(),
    );
  }

  getSchoolIdsForUserWithPermission(
    permission: Permission,
    permissionModule: PermissionModule,
  ): Observable<GetSchoolIdsForPermissionDtos> {
    return this.http.generic(
      BackendEndpoints.SCHOOL_ADMINS.GET_USER_SCHOOLS_WITH_PERMISSION({
        permission,
        permissionModule,
      }),
    );
  }

  syncAdminPermissions(schoolId: string): Observable<SchoolAdminPermission> {
    this.store.setLoading(true);

    return this.http
      .generic(BackendEndpoints.SCHOOL_ADMINS.GET_ADMIN_PERMISSION(schoolId))
      .pipe(
        switchMap((permission: SchoolAdminPermission) => {
          this.store.upsert(permission._id, permission);
          this.store.setActive(permission._id);
          this.store.setLoading(false);

          return of(permission);
        }),
      );
  }
}
